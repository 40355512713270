.tox-notification ,.tox-statusbar__help-text {
    display: none!important;
}

.tox-promotion {
    display: none!important;
}

.tox-pop--left {
    display: none!important;
}

.tox-label {
    white-space: pre-wrap!important;
}

.tox-menubar {
    gap: 12px;
}

.tox-statusbar__text-container {
    justify-content: space-between!important;
}
