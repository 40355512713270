.ant-table-pagination.ant-pagination {
  margin: 20px 0 0;
}

.ant-table-body::-webkit-scrollbar {
  @apply !h-1.5 md:!h-2.5;
}

.ant-table-body::-webkit-scrollbar-thumb {
  @apply !bg-primary !rounded;
}

