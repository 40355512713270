.ant-select-selector {
    @apply !shadow-none rounded-lg !px-3 !min-h-[36px] !bg-white hover:!border-input-border2 items-center;
}



.ant-select-multiple .ant-select-selection-search {
    margin-inline-start: 0 !important;
}

.ant-select-selector .ant-select-selection-search-input {
    @apply !h-full;
}

.ant-select-focused .ant-select-selector {
    @apply !border-primary;
}

.ant-select-selection-rr-search-input {
    @apply !h-full;
}

.ant-select-item-option-content {
    @apply !whitespace-pre-wrap;
}

.ant-select-selection-item {
    @apply !rounded-full
}

.select-clear-icon .ant-select-selection-item {
    padding-inline-end: 36px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    @apply !leading-[34px];
}

.pagination-select .ant-select-selector {
    @apply rounded-xl;
}

