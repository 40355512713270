.aside-menu {
    @apply custom-scrollbar-menu fixed top-6 left-6 flex transition duration-300
}


.aside-menu._collapsed:not(._float-visible) {
    transform: translateX(calc(-100% + var(--menu-width-m) - 1.5rem));
}

.aside-menu-float-trigger {
    width: 60px;
    height: 60px;
    border-radius: 25px;
    background-color: hsla(210, 19%, 16%, 1);
    display: none;
    align-items: center;
    justify-content: center;
    color: #D3D9E0;
    cursor: pointer;

    position: fixed;
    z-index: 999;
    left: 24px;
    bottom: 40px;
}

.aside-menu_collapse-button {
    @apply rounded-md transition duration-300 size-7.5 flex-center hover:bg-dark-50 hover:text-dark-gray
}

.aside-menu_collapse-button svg {
    @apply transition duration-300
}

.aside-menu._collapsed:not(._float-visible) .aside-menu_collapse-button {
    @apply translate-x-1.5
}

.aside-menu._collapsed:not(._float-visible) .aside-menu_collapse-button svg {
    @apply rotate-180
}

.aside-menu-navlink {
    @apply p-3 pr-3.5 rounded-xl relative flex justify-between items-center text-[#ffffffe6] hover:bg-[#1d232a] hover:text-gray;
}

.aside-menu-navlink._active {
    @apply bg-[#191e24] text-white hover:text-white;
}

.aside-menu-navlink._active span {
    text-shadow: 0 0 8px rgb(255 255 255 / 80%);
}

.aside-menu-navlink._active:not(._sub) svg {
    @apply text-white;
}

.aside-menu-active_icon svg {
    color: white;
    background-color: #191e24;
    text-shadow: 0 0 8px rgb(255 255 255 / 80%);
}

@media screen and (max-width: 1280px) {
    .aside-menu {
        @apply transition-all duration-300 invisible opacity-0 inset-0 z-[1000] backdrop-blur-lg flex justify-center items-end bg-black/40 pb-10
    }

    .aside-menu._float-visible {
        @apply visible opacity-100
    }

    .aside-menu_content {
        @apply translate-y-10 transition-all duration-300
    }

    .aside-menu._float-visible .aside-menu_content {
        @apply translate-y-0
    }

    .aside-menu-float-trigger {
        display: flex;
    }
}
