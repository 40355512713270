@import url("inc/libs/antd/antd.css");
@import url("inc/libs/custom-editor.modules.css");
@import url("inc/libs/emoji-picker-react.css");
@import url("inc/libs/wiris.css");

@import url("inc/global/alert.css");
@import url("inc/global/fonts.css");

@import url("inc/invoicing.css");
@import url("inc/profile.css");

@import url("inc/ui/main-menu.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --menu-width-m: 4.25rem;
    --menu-width: 16rem;
    --menu-width-abs: -16rem;
    --content-space: calc(var(--menu-width) + 1.5rem);
}

body {
  font-family: Onest, serif;
}

@layer utilities {
  .custom-scrollbar::-webkit-scrollbar {
    @apply w-2 h-2;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-gradient-to-r from-primary to-[#7C4894]/80 rounded;
  }
}

@layer utilities {
  .custom-scrollbar-menu::-webkit-scrollbar {
    @apply w-1 h-1;
  }

  .custom-scrollbar-menu::-webkit-scrollbar-thumb {
    @apply bg-gradient-to-r from-primary to-[#7C4894]/80 rounded;
  }
}

@layer utilities {
  .rotate-x-180 {
      transform: rotateX(180deg);
  }
}
