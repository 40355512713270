.custom-description .ant-descriptions-view {
    border-radius: 12px;
    overflow: hidden;
}

.custom-description .ant-descriptions-row {
    height: 40px;
}

.custom-description .ant-descriptions-row th {
    background-color: #F0F1F5!important;
    width: 50% !important;
}

.custom-description .ant-descriptions-item-label {
    padding-left: 24px!important;
}
