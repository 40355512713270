.ant-modal-header,
.ant-modal-body,
.ant-modal-footer {
    @apply p-4;
}

.ant-modal-content {
    @apply !shadow-none
}

.custom-antd-modal .ant-modal-content {
    border-radius: 36px;
}

.custom-antd-modal .ant-modal-header, .ant-modal-body, .ant-modal-footer {
    @apply p-0
}

.custom-antd-modal .ant-modal-header {
    @apply p-0 pb-4
}
