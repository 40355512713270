.wrs_modal_dialogContainer {
    height: 728px !important;
}

.wrs_panelContainer {
    max-height: 124px !important;
    min-height: 124px !important;
    height: 100% !important;
}

.wrs_modal_buttons_container, .wrs_modal_controls {
    display: inline-flex !important;
    align-items: center;
    gap: 0 8px;
}

.wrs_modal_buttons_container button {
    margin: 0 !important;
    text-transform: capitalize;
}

.wrs_popupmessage_button_area button {
    text-transform: capitalize;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
}
