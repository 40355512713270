.ant-picker {
  @apply !rounded-lg !py-1.5 !px-3 !bg-white !cursor-pointer;
}

.ant-picker input {
  @apply cursor-pointer;
}

.ant-picker-focused, .ant-picker:hover {
  @apply !border-input-border;
}

.ant-picker-focused {
  @apply shadow-none border-primary hover:border-primary;
}

.ant-picker-range-focused, .ant-picker-range:hover {
  @apply !border-input-border2;
}

.ant-picker-range-focused {
  @apply  border-primary hover:border-primary;
}

.ant-picker-panels {
  @apply  !justify-center !flex-wrap;
}

.ant-picker-cell-selected > div{
  @apply !bg-gradient-to-r from-primary to-[#7C4894]/80 !text-white
}

