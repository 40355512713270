.invoicing_buttons  {
    @apply max-md:p-4 p-6 overflow-x-auto max-w-full
}

.invoicing_buttons .ant-card-body {
    @apply p-0
}

.invoicing_buttons .ant-card-body section {
    @apply flex min-w-max
}

.invoicing_buttons .ant-card-body div {
    @apply flex-row-reverse rounded-xl text-sm
}

