@font-face {
  font-family: Onest;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../../../fonts/Onest/Onest-Bold.woff2") format("woff2"),
  url("../../../fonts/Onest/Onest-Bold.woff") format("woff");
}

@font-face {
  font-family: Onest;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../../../fonts/Onest/Onest-Medium.woff2") format("woff2"),
  url("../../../fonts/Onest/Onest-Medium.woff") format("woff");
}

@font-face {
  font-family: Onest;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../../../fonts/Onest/Onest-Regular.woff2") format("woff2"),
  url("../../../fonts/Onest/Onest-Regular.woff") format("woff");
}
