.ant-collapse-content-box {
    @apply !p-0
}

.custom-antd-accordion  {
   background: transparent;
    border-radius: 12px!important;
}

.custom-antd-accordion .ant-collapse-header {
    height: 40px!important;
    padding: 0!important;
    display: flex;
    align-items: center!important;
    position: relative;
    background: white!important;
    border-radius: 12px!important;
}

.custom-antd-accordion .ant-collapse-header .ant-collapse-header-text {
    padding-left: 24px;
}

.custom-antd-accordion .ant-collapse-header .ant-collapse-expand-icon {
    @apply pos-abs-y right-6
}

.custom-antd-accordion .ant-collapse-content-active {
    border-radius: 0 0 12px 12px!important;
}

.custom-antd-accordion .ant-collapse-expand-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(8px);
}
