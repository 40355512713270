.EmojiPickerReact .epr-header .epr-header-overlay {
  @apply !p-2;
}

.EmojiPickerReact .epr-search-container input.epr-search {
  @apply !h-9;
}

.EmojiPickerReact .epr-category-nav {
  @apply !p-2 !pt-0;
}

.EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label {
  @apply !h-7 !text-sm;
}

.EmojiPickerReact .epr-search-container .epr-icn-search {
  @apply !left-1 !h-6 !w-6 !bg-[length:24px];
}

.EmojiPickerReact button.epr-emoji {
  @apply !w-9 !h-9 !rounded-full;
}

.__EmojiPicker__.epr-emoji-native {
  @apply !text-2xl;
}
