.ant-input {
  @apply py-1.5 rounded;
}

.ant-input:hover {
  @apply !shadow-none !border-primary;
}

.ant-input:focus {
  @apply !shadow-none !border-primary;
}
